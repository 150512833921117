import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext } from "react";
import Context from "../../Context.js";
import BrandContainer from "../brand/BrandContainer.js";
import CustomButton from "../helperComponents/CustomButton.js";

const ParentContainer = styled.div`
  display: flex;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 60px;

  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  backdrop-filter: blur(200px);
  gap: 150px;
  border-radius: 10px;
  width: 38vw;
  flex: 1;
  /* border-radius: 0 20px 20px 0; */
  overflow: hidden;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  /* width: 100vw; */
  overflow-y: auto;

  @media (max-width: 1300px) {
    gap: 0;
    justify-content: space-between;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px 10px;
    gap: 0;
    justify-content: space-between;
    padding-top: 60px;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 35px;
  text-align: center;
  font-family: "Staatliches", sans-serif;

  @media (max-width: 900px) {
    font-size: 30px;
    width: 90%;
  }
`;
const MainButtons = styled.div`
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;
  align-items: center;
  border: none;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
    justify-content: space-between;
    /* justify-content: center; */
    /* flex-direction: column; */
  }
`;

const Medium = styled.div`
  font-size: 21px;
  opacity: 0.8;
  font-weight: 600;
  text-align: center;
  opacity: 0.5;
  line-height: 30px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    width: 90%;
    font-size: 15px;
    line-height: 23px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  /* margin-top: 90px; */
  width: 100%;
`;

const btnStyle = {
  background: "var(--glassGradient)",
  border: "1px solid var(--foreground)",
  boxShadow: "rgb(0 0 0 / 14%) 1px 1px 9px 1px",
};

export default function LoggedOutHomePage() {
  let { setForm } = useContext(Context);
  return (
    <ParentContainer>
      <Main>
        <BrandContainer />
        <TextContainer>
          <Tagline>Find people who want to talk about the same stuff.</Tagline>
          <Medium>
            Type in what you want to chat about and people with similar interest
            will reach out to you through chat. (You can also choose to make
            yourself anonymous)
          </Medium>
          <br />
        </TextContainer>

        <MainButtons>
          <CustomButton
            style={btnStyle}
            height={"60px"}
            width={"300px"}
            icon={<GrGoogle />}
            onClick={goToAuthScreen}
          >
            Login With Google
          </CustomButton>
          <CustomButton
            style={btnStyle}
            size="60px"
            onClick={openMoreMenu}
            icon={<HiMenuAlt4 />}
          ></CustomButton>
        </MainButtons>
      </Main>
    </ParentContainer>
  );
  function open(link) {
    return () => {
      return (window.location = link);
    };
  }
  function openMoreMenu() {
    setForm({
      options: [
        {
          name: "About us",
          onClick: open("https://arnav.upon.one"),
        },
      ],
    });
  }
}
