function getServerURL() {
  if (window.location.href.indexOf("192.168") !== -1)
    return "http://192.168.29.21:8080"; // For running the webpage locally on the phone.

  let base = "https://backend.upon.one";

  console.log("process.env", process.env);
  if (process.env.REACT_APP_SERVER) return process.env.REACT_APP_SERVER;

  return base;
}

export default getServerURL;
