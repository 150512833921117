import { useContext, useEffect, useState } from "react";
import WithHeader from "../core/WithHeader";
import BeautifulTitle from "../helperComponents/BeautifulTitle";
import CustomToggle from "../helperComponents/CustomToggle";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import FeedItem from "../cardForFeed/FeedItem";
import UserBox from "../profilePage/UserBox";
import styled from "styled-components";
import Context from "../../Context";
import getUrlParams from "../../controllers/getUrlParams";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35vw;
  gap: 50px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`;

export default function ConnectionsPage() {
  const { currentRoute } = useContext(Context);

  const [data, setData] = useState();

  const [loading, setLoading] = useState(true);

  let urlQuery = getUrlParams(currentRoute);
  let username = urlQuery.get("username");

  useEffect(() => {
    serverLine.get("/connections/?username=" + username).then((newData) => {
      setData(newData);
      setLoading(false);
    });
  }, [currentRoute]);

  if (loading)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  let list = [];

  list = data.map((item) => <UserBox item={item} />);

  return (
    <WithHeader>
      <Container>
        <BeautifulTitle>Connection of {username}</BeautifulTitle>

        <Main>
          <List>{list}</List>
        </Main>
      </Container>
    </WithHeader>
  );
}
