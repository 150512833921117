let workCategories = [
  { label: "General", value: "GENERAL" },
  { label: "Programming", value: "CONFESSION" },
  { label: "Design", value: "NEED_ADVICE" },
];

let contentCategories = {
  GENERAL: [
    //Broad
    { label: "Introspection", value: "INTROSPECTION" },
    { label: "Story", value: "STORY" },
    { label: "Common", value: "COMMON_STUFF" },

    //Work related
    { label: "Programming", value: "PROGRAMMING" },
    { label: "Startup", value: "STARTUP" },

    //Entertainment
    { label: "Humour", value: "HUMOUR" },
    { label: "Anime", value: "ANIME" },
    { label: "Movies", value: "MOVIES" },
    { label: "Geopolitics", value: "GEOPOLITICS" },
    { label: "Video Games", value: "VIDEO_GAMES" },
  ],
  DATING: [
    { label: "General", value: "GENERAL" },
    { label: "Poetry", value: "POETRY" },
    { label: "Story", value: "STORY" },
    { label: "Humour", value: "HUMOUR" },
    { label: "Rizz", value: "RIZZ" },
    { label: "Programming Rizz", value: "PROGRAMMING RIZZ" },
  ],
  ANONYMOUS: [
    { label: "General", value: "GENERAL" },
    { label: "Confession", value: "CONFESSION" },
    { label: "Need Advice", value: "NEED_ADVICE" },
    { label: "Regret", value: "REGRET" },
    { label: "Desire", value: "DESIRE" },
  ],
  WORK: workCategories,
};

export default contentCategories;
