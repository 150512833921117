import { styled } from "styled-components";

import getImageURL from "../../controllers/getImageURL";
import goToProfile from "../../controllers/goToProfile";
import ReportsDataBox from "../util/ReportsDataBox";
import limitStringLength from "../../controllers/limitStringLength";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  box-shadow: var(--lightShadow);

  &:hover {
    background-color: var(--foreground);
  }
`;

const Lines = styled.div`
  display: flex;
  flex: 1;
  height: 90px;
  flex-direction: column;
  padding: 20px;

  gap: 10px;
  border-radius: 0 10px 10px 0;
`;
const Line1 = styled.div`
  font-size: 21px;
  text-transform: capitalize;

  font-weight: 800;
`;
const Line2 = styled.div``;

const Image = styled.img`
  height: auto;
  height: 90px;
  width: 90px;
  background-color: #fff;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default function UserBox({ item, showReportsData, onClick }) {
  let content = (
    <Container onClick={onClick ? onClick : goToProfile(item.username)}>
      <Image src={getImageURL(item.profileImage, true)} />
      <Lines>
        <Line1>{item.username}</Line1>
        <Line2>{limitStringLength(item.tagline, 50)}</Line2>
      </Lines>
    </Container>
  );

  if (showReportsData) {
    return (
      <Div>
        {content}
        <ReportsDataBox item={item} subjectType={"USER"} />
      </Div>
    );
  }

  return content;
}
