import { useContext } from "react";
import Context from "../../Context";
import { styled } from "styled-components";

import TickStatus from "./TickStatus";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
`;

const TheMessage = styled.p`
  border-radius: 20px 0 20px 20px;
  padding: 15px 20px;
  margin: 0;
  max-width: 62%;

  background: var(--foreground);
  box-shadow: var(--lightShadow);

  @media (max-width: 900px) {
    max-width: 80%;
  }
`;

export default function MyMessage({ message, group, oppositeMember, content }) {
  const { loggedInUserID } = useContext(Context);

  let oppositeMemberLastSeenAt = null;

  if (group.notificationData[oppositeMember._id]) {
    oppositeMemberLastSeenAt =
      group.notificationData[oppositeMember._id].lastSeenAt;
  }

  return (
    <Container data-opp={oppositeMemberLastSeenAt}>
      <TheMessage>{content}</TheMessage>
      <TickStatus
        message={message}
        oppositeMemberLastSeenAt={oppositeMemberLastSeenAt}
      />
    </Container>
  );
}
