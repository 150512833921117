import { styled } from "styled-components";

import getImageURL from "../../controllers/getImageURL";
import { useContext } from "react";
import Context from "../../Context";

import getDateString from "../../controllers/getDateString";
import getUnreadMessageCount from "../../controllers/utils/getUnreadMessageCount";
import getOnlineOfflineStatus from "../../controllers/socket/getOnlineOfflineStatus";
import limitStringLength from "../../controllers/limitStringLength";
import OnlineBubble from "./OnlineBubble";
import getAnonymousIcon from "../../controllers/getChatTitle";
import YouAnonymousComp from "../../controllers/YouAnonymousComp";
import getChatTitle from "../../controllers/getChatTitle";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border-bottom: 1px solid var(--translucentHard);

  ${({ highlight }) => {
    if (highlight)
      return `
        background: var(--glassGradientHard);

    `;

    return `
    &:hover{
     background: var(--glassGradient);
    }
       
    `;
  }}

  @media (max-width:900px) {
    ${({ isLastItem }) => {
      if (isLastItem)
        return `
        border: none;
    `;
    }}
  }
`;
const Image = styled.img`
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 500px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 21px;
  gap: 7px;
  /* width: calc(100% - 80px); */
  overflow: hidden;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  white-space: nowrap;
  /* width: 80%; */
  overflow: hidden;
`;
const Subtext = styled.div`
  font-size: 13px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-weight: 700;
  gap: 10px;
`;
const UnreadMessages = styled.div`
  background-color: var(--accentColor);
  padding: 5px 8px;
  border-radius: 20px;
  color: var(--foreground);
  font-size: 11px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export default function ChatGroupBox({
  item,
  onClick,
  friendsLastSeenAt,
  highlight,
  isLastItem,
}) {
  item = { ...item };

  if (!item.oppositeMember) {
    item.oppositeMember = { name: "Anonymous", bio: "", profileImage: null };
  }

  let onlineStatus = getOnlineOfflineStatus({
    friendsLastSeenAt,
    oppositeMemberID: item.oppositeMemberID,
  });

  let unreadMessages = getUnreadMessages();

  let title = getChatTitle({
    chatGroup: item,
    oppositeMember: item.oppositeMember,
  });

  return (
    <Container
      isLastItem={isLastItem}
      highlight={highlight}
      onClick={() => {
        onClick(item);
      }}
    >
      <ImageContainer>
        <Image src={getImageURL(item.oppositeMember.profileImage, true)} />

        {onlineStatus ? <OnlineBubble> </OnlineBubble> : null}
      </ImageContainer>

      <Text>
        <Name>{limitStringLength(title, 25)}</Name>
        <Subtext>
          <YouAnonymousComp chatGroup={item} />

          <YouAnonymousComp
            chatGroup={item}
            forOppositeUser={true}
            oppositeMember={item.oppositeMember}
          />

          {getUsername()}
          {unreadMessages}
        </Subtext>
      </Text>
    </Container>
  );

  function getUsername() {
    if (item.oppositeMember.isAnonymous)
      return `${item.oppositeMember.username}`;
    return "@" + item.oppositeMember.username;
  }

  function getUnreadMessages() {
    let initial = ` . ${getDateString(item.lastMessageSentAt)}`;

    let val = getUnreadMessageCount(item);

    return val ? <UnreadMessages>{val}</UnreadMessages> : initial;
  }
}
