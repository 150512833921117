import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownInput from "../helperComponents/DropDownInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import getImageURL from "../../controllers/getImageURL.js";
import { BiImage } from "react-icons/bi";

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--lightShadow);
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--lightShadow);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const BigImage = styled.img`
  width: 100%;
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  height: auto;
`;

const ImageButton = styled.div`
  display: flex;
  flex: 1;
  height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  background: var(--glassGradientHard);
  border: 1px solid var(--foreground);
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    height: 100px;
  }
`;
const ImageButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageButtonText = styled.div``;

const ImageAfterUploadButton = styled.img`
  display: flex;
  flex: 1;
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;

  @media (max-width: 900px) {
    height: 100px;
  }
`;

const PopupImage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 25px;
`;

const PopupButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function GalleryEditorAndPresenter({
  allowEditing,
  images,
  userData,
  setUserData,
}) {
  const { loggedInUser, setLoggedInUser, popupAlert, setForm } =
    useContext(Context);

  const [loading, setLoading] = useState(false);

  if (loading)
    return (
      <LoadingContainer>
        <LoadingSection />
      </LoadingContainer>
    );

  if (!allowEditing) {
    if (!images) return null;

    if (images.length < 3) return null;
  }

  if (!images) images = [];

  return (
    <Container>
      {getImageButton(0)}
      {getImageButton(1)}
      {getImageButton(2)}
    </Container>
  );

  function getImageButton(index) {
    let theImage = images[index];

    if (!theImage)
      return (
        <ImageButton
          onClick={() => {
            imageClicked(index);
          }}
        >
          <ImageButtonIcon>
            <BiImage />
          </ImageButtonIcon>
          <ImageButtonText>Upload Image</ImageButtonText>
        </ImageButton>
      );

    return (
      <ImageAfterUploadButton
        onClick={() => {
          imageClicked(index);
        }}
        src={getImageURL(theImage)}
      />
    );
  }

  function imageClicked(index) {
    let theImage = images[index];

    if (!allowEditing) {
      if (!theImage) return popupAlert("Image Not Added");
      setForm({
        title: "Image",
        component: (
          <PopupImage>
            <BigImage src={getImageURL(theImage)} />
          </PopupImage>
        ),
      });
    } else {
      if (!theImage) return chooseImage(index);

      setForm({
        title: "Image",
        component: (
          <PopupImage>
            <BigImage src={getImageURL(theImage)} />
            <PopupButtons>
              <CustomButton
                variant="filled"
                onClick={() => {
                  setForm(null);
                  chooseImage(index);
                }}
              >
                Change Image
              </CustomButton>
            </PopupButtons>
          </PopupImage>
        ),
      });
    }
  }

  async function chooseImage(index) {
    let selectedImages = await selectFile({ onlyImage: true });

    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);

    try {
      let fileData = await compressAndUploadFile(null, selectedImages[0]);

      let newImages = [...images];

      if (fileData) {
        if (fileData.fileName) {
          newImages[index] = {
            type: "S3_UPLOAD",
            data: fileData.fileName,
          };
        }
      }

      await save(newImages);

      setLoading(false);
    } catch (e) {
      window.popupAlert(e.message);
      setLoading(false);
    }
  }

  async function save(images) {
    try {
      await serverLine.patch("/profile", {
        changes: { images },
      });

      let newLoggedInUser = { ...loggedInUser, images };

      if (userData) {
        let newUserData = { ...userData };
        newUserData.user.images = images;

        if (newUserData.contents) {
          if (newUserData.contents.DATING) {
            for (let itm of newUserData.contents.DATING) {
              if (itm.author) itm.author.images = images;
            }
          }
        }

        setUserData(newUserData);
      }

      setLoggedInUser(newLoggedInUser);
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
