import styled from "styled-components";
import CustomButton from "../helperComponents/CustomButton";

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid var(--translucentHard);
  margin-top: 20px;
`;

export default function ReportsDataBox({ item, subjectType }) {
  return (
    <Container>
      <CustomButton style={{ borderRadius: "10px" }} variant="minimal">
        {item.type}
      </CustomButton>

      <CustomButton style={{ borderRadius: "10px" }} variant="minimal">
        {item.category}
      </CustomButton>

      <CustomButton style={{ borderRadius: "10px" }} variant="minimal">
        New Rep:{item.newReportsCount}
      </CustomButton>

      <CustomButton style={{ borderRadius: "10px" }} variant="minimal">
        Old Rep:
        {item.oldReportsCount}
      </CustomButton>

      <CustomButton
        style={{ borderRadius: "10px" }}
        variant="minimal-underlined"
        href={`/show-reports/?subjectID=${item._id}&subjectType=${subjectType}`}
      >
        Show All Reports
      </CustomButton>
    </Container>
  );
}
