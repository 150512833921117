import { useContext } from "react";
import { PiMaskHappyFill } from "react-icons/pi";
import styled from "styled-components";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--color);
  color: var(--foreground);
  gap: 5px;
  padding: 5px;
  border-radius: 10px;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
`;

export default function YouAnonymousComp({
  chatGroup,
  forOppositeUser,
  oppositeMember,
}) {
  const { loggedInUser } = useContext(Context);
  let theUser = loggedInUser;
  let userID = loggedInUser.anonymousID;

  if (oppositeMember) {
    theUser = oppositeMember;
    userID = theUser._id;
  }

  let comp = (
    <Container>
      <Icon>
        <PiMaskHappyFill />
      </Icon>
      <Text>{forOppositeUser ? "Person" : "You"}</Text>
    </Container>
  );

  if (chatGroup.contentType == "ANONYMOUS") return comp;

  if (
    chatGroup.type === "ANONYMOUS_DIRECT_CHAT" &&
    chatGroup.directChatAnonymousUserID === userID
  ) {
    return comp;
  }

  if (!forOppositeUser) {
    console.log("chatGroup, loggedInUser", loggedInUser, chatGroup);
  }

  return false;
}
