import { useContext, useEffect, useState } from "react";
import WithHeader from "../WithHeader";

import { serverLine } from "../../../controllers/serverLine";
import { useParams } from "react-router-dom";

import goTo from "../../../controllers/goTo";
import { styled } from "styled-components";

import Context from "../../../Context";
import reachChatPanelEnd from "../../../controllers/reachChatPanelEnd";
import { io } from "socket.io-client";
import getOppositeMemberID from "../../../controllers/getOppositeMemberID";

import getSubPath from "../../../controllers/getSubPath";
import ChatGroupBox from "../../chat/ChatGroupBox";
import ChatPanel from "../../chat/ChatPanel";
import PopupLogin from "../../auth/PopupLogin";
import LoadingSection from "../../helperComponents/LoadingSection";
import getServerURL from "../../../controllers/getServerURL";

export default function SetupChat() {
  const {
    setChatLoading,
    setSelectedGroupData,
    setGroups,
    setIsConnected,
    setFriendsLastSeenAt,
    setOppositeMemberLastTypedAt,
  } = useContext(Context);

  const { loggedInUserID } = useContext(Context);

  window.loadChatPage = loadChatPage;
  window.loggedInUserID = loggedInUserID;
  window.onMessageReceived = onMessageReceived;

  let groupID = null;

  if (window.location.href.indexOf("/chat/") !== -1) {
    groupID = getSubPath(1);
  }
  window.groupID = groupID;

  useEffect(() => {
    if (!groupID) setSelectedGroupData(null);
  }, [groupID]);

  useEffect(() => {
    return initializeChat();
  }, []);

  return null;

  function initializeChat() {
    let serverURL = getServerURL();

    console.log("IO ServerURL", serverURL);

    let socket = io(serverURL, {
      query: { authToken: localStorage.getItem("cottonchat-token") },
      path: "/cottonchat-socket",
      reconnection: true,
      reconnectionAttempts: 60 * 60 * 24,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    });

    function onConnect() {
      setIsConnected(true);
      loadChatPage();
    }

    function onDisconnect() {
      setIsConnected(false);
      // initializeChat();
      //Socket.io automatically handles reconnection
    }

    window.chatSocket = socket;

    socket.on("typing", (data) => {
      if (window.selectedGroupData) {
        if (window.selectedGroupData.group.groupID == data.groupData.groupID) {
          if (data.hasStopped) return setOppositeMemberLastTypedAt(null);
          setOppositeMemberLastTypedAt(new Date().toISOString());
        }
      }
    });

    socket.on("error", (data) => {
      window.doAlert(data.data);
    });

    socket.on("success", () => {
      if (window.pingInterval) window.clearInterval(window.pingInterval);

      window.pingInterval = setInterval(() => {
        socket.emit("ping", { friends: getFriends() });
      }, 5 * 1000);
    });

    socket.on("pingResponse", onPingResponse);

    socket.on("seen", markAsSeen);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("message", onMessageReceived);

    return () => {
      socket.disconnect();
    };
  }

  function loadChatPage() {
    let groupID = window.groupID;
    if (!groupID) setSelectedGroupData(null);

    console.log("Loading chat data ##########");
    setChatLoading(true);

    let url = "/chat-groups/?groupID=" + groupID;

    if (!groupID) {
      url = "/chat-groups";
    }

    serverLine
      .get(url)
      .then((newData) => {
        setGroups(newData.chatGroups);

        if (newData.selectedGroupData) {
          setSelectedGroupData(newData.selectedGroupData);

          sendAllSeenMessage({
            groupData: newData.selectedGroupData.group,
            receiverUserID: newData.selectedGroupData.oppositeMemberID,
          });
        } else {
          setSelectedGroupData(null);
        }

        setChatLoading(false);
      })
      .catch((e) => {
        window.popupAlert(e.message);
      });
  }

  function onPingResponse(data) {
    if (data.lastSeenAt) {
      setFriendsLastSeenAt(data.lastSeenAt);
    }
  }

  function getFriends() {
    if (!window.groups) return [];

    let friends = [];

    for (let item of window.groups) {
      let friendID = item.oppositeMemberID;

      if (!friends.includes(friendID)) {
        friends.push(friendID);
      }
    }

    return friends;
  }

  function markAsSeen({ groupID }) {
    console.log("Has seen is received!!!", groupID);
    if (!window.selectedGroupData) return;

    if (window.selectedGroupData.group.groupID !== groupID) return;

    let newSelectedGrp = { ...window.selectedGroupData };

    for (let item of newSelectedGrp.messages) {
      if (item.isNew === true) {
        item.isSeen = true;
      }
    }

    console.log(newSelectedGrp);

    window.setSelectedGroupData(newSelectedGrp);
  }

  function sendAllSeenMessage({ groupData, receiverUserID }) {
    window.chatSocket.emit("seen", {
      groupData,
      receiverUserID,
    });
  }

  function onMessageReceived(message) {
    let content = message.content;
    let groupData = message.groupData;
    let sender = message.sender;

    let groupID = groupData.groupID;

    let messageIsForSelectedGroup = false;

    if (
      window.selectedGroupData !== "LOADING" &&
      window.selectedGroupData !== null
    ) {
      if (window.selectedGroupData.group.groupID == groupID) {
        messageIsForSelectedGroup = true;

        sendAllSeenMessage({
          groupData: window.selectedGroupData.group,
          receiverUserID: sender._id,
        });

        let oldSelectedGrpData = { ...window.selectedGroupData };

        oldSelectedGrpData.messages.push({
          message: content,
          authorUserID: sender._id,
        });

        window.setSelectedGroupData(oldSelectedGrpData);
      }
    }

    if (!messageIsForSelectedGroup) {
      let groupFound = false;

      let newGroups = [...window.groups];

      for (let item of newGroups) {
        if (item.groupID == groupID) {
          groupFound = true;
          item.lastMessageSentAt = new Date().toISOString();
          item.notificationData[item.myID].unreadMessages += 1;
        }
      }

      if (!groupFound) {
        //Removed because new group has unread message already marked
        // groupData.notificationData[groupData.myID].unreadMessages++;

        newGroups = [
          {
            ...groupData,
          },
          ...newGroups,
        ];
      }

      window.setGroups(rankGroups(newGroups));
    }
  }

  function rankGroups(theArray) {
    return theArray.sort(function (a, b) {
      a = new Date(a.lastMessageSentAt).getTime();
      b = new Date(b.lastMessageSentAt).getTime();

      return b - a;
    });
  }
}
