import { styled } from "styled-components";
import getImageURL from "../../controllers/getImageURL";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

const TheMessage = styled.p`
  border-radius: 0 20px 20px 20px;
  padding: 15px 20px;
  max-width: 62%;
  /* white-space: pre; */
  margin: 0;
  background: var(--foreground);
  box-shadow: var(--lightShadow);

  @media (max-width: 900px) {
    max-width: 80%;
  }
`;

const Image = styled.img`
  height: 30px;
  border-radius: 500px;
  width: 30px;
  object-fit: cover;
`;

export default function TheirMessage({
  message,
  group,
  oppositeMember,
  content,
}) {
  return (
    <Container>
      <Image src={getImageURL(oppositeMember.profileImage, true)} />
      <TheMessage>{content}</TheMessage>
    </Container>
  );
}
