import styled from "styled-components";
import goTo from "../../controllers/goTo";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default function Link({ href, children }) {
  return <Div onClick={onClick}>{children}</Div>;

  function onClick() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
    goTo(href)();
  }
}
