import { styled } from "styled-components";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../controllers/serverLine";
import FeedItem from "../../cardForFeed/FeedItem";
import MaterialInput from "../../helperComponents/MaterialInput";
import CustomToggle from "../../helperComponents/CustomToggle";
import Context from "../../../Context";
import WithHeader from "../WithHeader";
import LoadingSection from "../../helperComponents/LoadingSection";
import UserBox from "../../profilePage/UserBox";
import FeedItemMini from "../../cardForFeed/FeedItemMini";
import FeedItemMobile from "../../cardForFeed/FeedItemMobile";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
`;

const Main = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 38vw;
  gap: 40px;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 0;
    gap: 15px;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  font-size: 21px;
  background-color: var(--translucent);
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 300;
  flex-direction: row;
  align-items: center;
  display: flex;
  opacity: 0.7;
  gap: 10px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const LocationFilter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;
`;

export default function Search({}) {
  const { loggedInUser, isMobile } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [type, setType] = useState("POST");

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    doQuery();
  }, [type]);

  async function doQuery() {
    if (searchQuery) {
      setLoading(true);
      setList([]);
      let res = await serverLine.get(
        `/search/?&type=${type}&query=${searchQuery}`
      );
      setList(res);
      setLoading(false);
    }
  }

  if (loading)
    return (
      <WithHeader>
        <Container>
          <LoadingSection />
        </Container>
      </WithHeader>
    );

  let items = [];

  if (type == "PROFILE") {
    items = list.map((item) => <UserBox item={item} />);
  } else {
    items = list.map((item) =>
      isMobile ? <FeedItemMobile item={item} /> : <FeedItemMini item={item} />
    );
  }

  let content = <List>{items}</List>;

  if (!list.length) content = <Title>Nothing found</Title>;

  let typeOptions = [
    { label: "Content", value: "POST" },
    { label: "Users", value: "PROFILE" },
  ];

  return (
    <WithHeader>
      <Container>
        <Main>
          <MaterialInput
            label={"Search"}
            value={searchQuery}
            onEnter={doQuery}
            onChange={updateSearchQuery}
          />

          <CustomToggle
            value={type}
            onChange={(newType) => {
              setList([]);
              setType(newType);
            }}
            options={typeOptions}
          />

          {content}
        </Main>
      </Container>
    </WithHeader>
  );

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }
}
