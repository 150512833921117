import { useContext, useState } from "react";
import { serverLine } from "../../controllers/serverLine";
import styled from "styled-components";
import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";

import { AiOutlineSend } from "react-icons/ai";

import { MoonLoader } from "react-spinners";
import goTo from "../../controllers/goTo";
import getHowOldInString from "../../controllers/utils/getHowOldInString";
import limitStringLength from "../../controllers/limitStringLength";
import RenderDatingImages from "./RenderDatingImages";
import CustomButton from "../helperComponents/CustomButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import ReportsDataBox from "../util/ReportsDataBox";
import handleSendingIfBlocked from "../../controllers/utils/handleSendingIfBlocked";
import FeedItem from "./FeedItem";
import LoadingSection from "../helperComponents/LoadingSection";
import BarLoader from "../helperComponents/BarLoader";
import MaterialInput from "../helperComponents/MaterialInput";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  /* border-bottom: 1px solid var(--translucentHard); */
  gap: 15px;
  background: var(--glassGradientHard);
  width: 100%;
  height: 175px;
  justify-content: center;
  box-shadow: var(--lightShadow);
  border-radius: 10px;
  border: 1px solid var(--foreground);
  backdrop-filter: contrast(1) brightness(1.05);

  @media (max-width: 900px) {
    /* height: 120px; */
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default function DirectMessageBox({ item }) {
  const { setForm, loggedInUserID, popupAlert } = useContext(Context);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);
  const [sent, setSent] = useState(false);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (isDeleted) return null;

  if (sending)
    return (
      <Main>
        <BarLoader />
      </Main>
    );

  return (
    <Main>
      <MaterialInput
        multiline={true}
        rows={2}
        label={"Message Box"}
        placeholder={`Message ${item.username} `}
        onChange={updateMessage}
        value={message}
      />

      <Buttons>
        <CustomButton
          style={{ borderRadius: "15px" }}
          onClick={sendMessage()}
          icon={<AiOutlineSend />}
        >
          Send
        </CustomButton>

        <CustomButton
          style={{ borderRadius: "15px" }}
          onClick={sendMessage(true)}
          icon={<AiOutlineSend />}
        >
          Anonymously
        </CustomButton>
      </Buttons>
    </Main>
  );

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  function sendMessage(isAnonymous) {
    return async () => {
      let theMessage = message;
      if (theMessage) theMessage = theMessage.trim();

      if (!theMessage) return;

      setSending(true);

      try {
        let data = await serverLine.post("/initial-message", {
          message: { text: theMessage },
          groupType: isAnonymous ? "ANONYMOUS_DIRECT_CHAT" : "DIRECT_CHAT",
          receiverUserID: item._id,
        });

        if (data.chatGroup) {
          if (handleSendingIfBlocked({ data, popupAlert })) {
            setSending(false);
            return;
          }

          let theGroup = data.chatGroup;

          let content = { text: theMessage };

          window.chatSocket.emit("message", {
            content,
            groupData: theGroup,
            receiverUserID: data.receiverUserID,
          });
        }

        setSent(true);
        popupAlert("Message Sent");
        setMessage("");

        setSending(false);
      } catch (e) {
        setSending(false);
        popupAlert(e.message);
      }
    };
  }
}
