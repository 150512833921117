import ChatPage from "../components/core/routes/ChatPage";
import ContentPage from "../components/core/routes/ContentPage";
import CreatePage from "../components/core/routes/CreatePage";
import CreateReport from "../components/core/routes/CreateReport";
import DoFakeLogin from "../components/core/routes/DoFakeLogin";
import EditContent from "../components/core/routes/EditContent";
import HomePage from "../components/core/routes/HomePage";
import MoreMenu from "../components/core/routes/MoreMenu";
import ProfileEditor from "../components/core/routes/ProfileEditor";
import Search from "../components/core/routes/Search";
import SendAuthCode from "../components/core/routes/SendAuthCode";
import WithHeader from "../components/core/WithHeader";
import AskPreferenceDetails from "../components/editors/AskPreferenceDetails";
import BanManagementDashboard from "../components/report/BanManagementDashboard";
import Blocklist from "../components/report/Blocklist";
import ConnectionsPage from "../components/report/ConnectionsPage";
import ReportsDashboardPage from "../components/report/ReportsDashboardPage";
import ShowReportsPage from "../components/report/ShowReportsPage";

let pathVsComp = {
  "/": <HomePage type="GENERAL" />,
  "/dating": <HomePage type="DATING" />,
  "/anonymous": <HomePage type="ANONYMOUS" />,
  "/work": <HomePage type="WORK" />,
  "/content": (
    <WithHeader>
      <ContentPage />
    </WithHeader>
  ),
  "/more-menu": <MoreMenu />,
  "/chat": <ChatPage />,
  "/edit-content": <EditContent />,
  "/create-report": <CreateReport />,
  "/create": <CreatePage />,
  "/edit-profile": <ProfileEditor />,
  "/auth-redirect": <SendAuthCode />,
  "/reports-dashboard": <ReportsDashboardPage />,
  "/show-reports": <ShowReportsPage />,
  "/manage-bans": <BanManagementDashboard />,
  "/blocklist": <Blocklist />,
  "/connections": <ConnectionsPage />,
  "/search": <Search />,
  "/fake-login": <DoFakeLogin />,
  "/edit-additional-details": (
    <WithHeader>
      <AskPreferenceDetails title={"Edit additional details"} />
    </WithHeader>
  ),
};

export default pathVsComp;
