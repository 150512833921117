import CustomButton from "../../helperComponents/CustomButton";
import { styled } from "styled-components";
import goTo from "../../../controllers/goTo";
import { serverLine } from "../../../controllers/serverLine";
import { useContext, useState } from "react";
import WithHeader from "../WithHeader";
import {
  MdConnectWithoutContact,
  MdNoiseAware,
  MdOutlineDarkMode,
  MdOutlineLightbulb,
  MdPersonRemove,
} from "react-icons/md";

import { PiIntersectDuotone } from "react-icons/pi";
import Context from "../../../Context";
import {
  AiOutlineBarChart,
  AiOutlineEdit,
  AiOutlineUser,
} from "react-icons/ai";
import openPaymentPopup from "../../../controllers/payment/openPaymentPopup";
import { RiCoinLine } from "react-icons/ri";

import LoadingSection from "../../helperComponents/LoadingSection";
import logout from "../../../controllers/logout";

import adminAccess from "../../../data/adminAccess";
import BeautifulTitle from "../../helperComponents/BeautifulTitle";
import Notifications from "./Notifications";
import { LiaBanSolid } from "react-icons/lia";
import { BiLock, BiNetworkChart } from "react-icons/bi";
import { GoReport } from "react-icons/go";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
  /* height: 400px; */
`;
const Title = styled.div`
  font-size: 21px;
  text-align: center;
  font-weight: 300;
`;
const Types = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 25px;
`;

const Message = styled.div`
  margin-top: 200px;
`;

let variant = "filled";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default function MoreMenu() {
  const { loggedInUser, loggedInUserID, colorMode, updateDarkMode } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  if (!loggedInUserID)
    return (
      <WithHeader>
        <Message>Login to continue</Message>
      </WithHeader>
    );

  if (loading || !loggedInUser)
    return (
      <WithHeader>
        <LoadingSection />
      </WithHeader>
    );

  return (
    <WithHeader>
      <Container>
        <Menu>
          <Title>Dashboard</Title>

          <Types>
            <CustomButton
              variant={variant}
              href="/edit-profile"
              icon={<AiOutlineEdit />}
            >
              Edit Profile
            </CustomButton>

            <CustomButton
              variant={variant}
              href="/edit-additional-details"
              icon={<AiOutlineEdit />}
            >
              Edit Additional Details
            </CustomButton>

            <CustomButton
              variant={variant}
              href={"/connections/?username=" + loggedInUser.username}
              icon={<BiNetworkChart />}
            >
              My Connections
            </CustomButton>

            <CustomButton variant={variant} href="/blocklist" icon={<BiLock />}>
              Blocklist
            </CustomButton>

            {isAdmin ? (
              <>
                <CustomButton
                  variant={variant}
                  href="/reports-dashboard"
                  icon={<GoReport />}
                >
                  Reports Dashboard
                </CustomButton>

                <CustomButton
                  variant={variant}
                  href="/manage-bans"
                  icon={<LiaBanSolid />}
                >
                  Manage Bans
                </CustomButton>
              </>
            ) : null}

            <CustomButton
              variant={variant}
              isLoading={loadingLogout}
              onClick={doLogout}
              icon={<MdPersonRemove />}
            >
              Logout
            </CustomButton>
          </Types>
        </Menu>

        <Notifications />
      </Container>
    </WithHeader>
  );

  async function doLogout() {
    setLoadingLogout(true);
    logout();
  }
}
