import pathVsComp from "../../data/pathVsComp";

export default function getPopupType(pathName) {
  let pathSplit = pathName.split("/");

  let firstSplit = pathSplit[1];
  if (!firstSplit) return false;
  if (firstSplit == "content") return "CONTENT";
  if (compForPageExists("/" + firstSplit)) {
    if (isProfilePopup(pathName)) {
      return "PROFILE";
    }
  }

  return false;
}

function compForPageExists(path) {
  if (pathVsComp[path]) return false;

  return true;
}

function isProfilePopup(path) {
  const queryString = path.split("?")[1];

  const urlParams = new URLSearchParams(queryString);

  if (urlParams.has("profilePopup")) {
    return true;
  } else {
    return false;
  }
}
