import { styled } from "styled-components";

import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";

import MessageBox from "./MessageBox";

import Context from "../../Context";
import getImageURL from "../../controllers/getImageURL";
import reachChatPanelEnd from "../../controllers/reachChatPanelEnd";
import { nanoid } from "nanoid";
import getOppositeMemberID from "../../controllers/getOppositeMemberID";

import onChangeStop from "../../controllers/onChangeStop";
import selectFile from "../../controllers/selectFile";
import compressAndUploadFile from "../../controllers/compressAndUploadFile";
import { GoImage } from "react-icons/go";
import goTo from "../../controllers/goTo";
import { BsChevronLeft, BsThreeDotsVertical } from "react-icons/bs";
import MaterialInput from "../helperComponents/MaterialInput";
import CustomButton from "../helperComponents/CustomButton";
import LoadingSection from "../helperComponents/LoadingSection";
import { serverLine } from "../../controllers/serverLine";
import goToProfile from "../../controllers/goToProfile";
import getProfileLink from "../../controllers/getProfileLink";
import SemanticButton from "../helperComponents/SemanticButton";
import getOnlineOfflineStatus from "../../controllers/socket/getOnlineOfflineStatus";
import limitStringLength from "../../controllers/limitStringLength";
import handleSendingIfBlocked from "../../controllers/utils/handleSendingIfBlocked";
import BlockUnblockInterface from "../profilePage/BlockUnblockInteface";
import getUserIdentityType from "../../controllers/blocking/getUserIdentityType";
import OnlineBubble from "./OnlineBubble";
import getAnonymousIcon from "../../controllers/getChatTitle";
import YouAnonymousComp from "../../controllers/YouAnonymousComp";
import getChatTitle from "../../controllers/getChatTitle";

const Container = styled.div`
  height: calc(100vh - 25px - 25px);

  display: flex;
  position: relative;
  flex-direction: column;

  @media (max-width: 900px) {
    border: none;
    display: flex;
    ${({ groupID }) => {
      if (!groupID) return `display: none;`;
    }}
    position:fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100svh;
    width: 100vw;
    background: var(--glassGradientHard);
    backdrop-filter: blur(20px);
    z-index: 500;
  }
`;
const TopPart = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--translucentHard);
  width: 100%;

  @media (max-width: 900px) {
    gap: 10px;
    padding: 10px;
  }
`;

const TopProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const TopProfileLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  transition: all 0.15s ease-in;
`;

const Messages = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;
const BottomPart = styled.div`
  /* height: 100px; */
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 10px;

  justify-content: space-between;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    padding: 20px 20px;
    border: none;
  }
`;
const Image = styled.img`
  height: 47px;
  width: 47px;
  object-fit: cover;
  border-radius: 50px;
`;
const Name = styled.div`
  font-weight: 800;
  font-size: 15px;
  text-transform: capitalize;
  /* white-space: nowrap; */
  width: 100%;
  overflow: hidden;
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.7;
  gap: 15px;
  font-size: 14px;
  font-weight: 500;
`;

const Typing = styled.div``;

const Username = styled.div`
  &:hover {
    text-decoration: underline;
    /* transform: scale(0.9); */
  }
`;

const BackButton = styled.div`
  opacity: 0.5;
  display: none;
  @media (min-width: 900px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export default function ChatPanel({
  loadNewMessages,
  groupID,
  selectedGroupData,
  addMessage,
  markAsSent,
  friendsLastSeenAt,
  oppositeMemberLastTypedAt,
}) {
  const { popupAlert, setForm, isMobile } = useContext(Context);
  const [messageText, setMessageText] = useState("");
  const { loggedInUserID } = useContext(Context);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Scrolling to the end");
    reachChatPanelEnd();
  }, [selectedGroupData]);

  if (selectedGroupData == "LOADING")
    return (
      <Container groupID={true}>
        <LoadingSection />
      </Container>
    );

  if (!selectedGroupData)
    return (
      <Container groupID={groupID}>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let loggedInUserIsBlocked = selectedGroupData.loggedInUserIsBlocked;
  let oppositeUserIsBlocked = selectedGroupData.oppositeUserIsBlocked;

  let group = selectedGroupData.group;
  let oppositeMember = selectedGroupData.oppositeMember;
  let messages = selectedGroupData.messages;

  let btnStyle = { borderRadius: "10px" };

  let theBottomPart = (
    <>
      <MaterialInput
        onEnter={postMessage}
        label={"Type Message Here"}
        onChange={updateMessage}
        value={messageText}
        maxRows={5}
        multiline={true}
      />

      <CustomButton
        width="56px"
        height="56px"
        variant="filled"
        onClick={uploadImage}
        icon={<GoImage />}
        style={btnStyle}
      />

      {isMobile ? null : (
        <CustomButton
          width="56px"
          height="56px"
          variant="filled"
          onClick={postMessage}
          style={btnStyle}
          icon={<AiOutlineSend />}
        />
      )}
    </>
  );

  let onlineStatus = getOnlineOfflineStatus({
    friendsLastSeenAt,
    oppositeMemberID: selectedGroupData.oppositeMemberID,
  });

  let title = getChatTitle({
    chatGroup: selectedGroupData.group,
    oppositeMember: selectedGroupData.oppositeMember,
  });

  return (
    <Container groupID={groupID}>
      <TopPart>
        <BackButton>
          <CustomButton
            variant="minimal"
            style={{ padding: "0" }}
            height="50px"
            fontSize="20px"
            onClick={goTo(-1)}
            icon={<BsChevronLeft />}
          />
        </BackButton>

        <TopProfile>
          <TopProfileLeft>
            <ImageContainer>
              <Image src={getImageURL(oppositeMember.profileImage, true)} />
              {onlineStatus ? <OnlineBubble /> : null}
            </ImageContainer>

            <TextSection>
              <Name>{limitStringLength(title, isMobile ? 20 : 50)}</Name>
              <SubHeading>
                <YouAnonymousComp chatGroup={selectedGroupData.group} />
                <YouAnonymousComp
                  chatGroup={selectedGroupData.group}
                  forOppositeUser={true}
                  oppositeMember={selectedGroupData.oppositeMember}
                />
                {getOnlineOfflineStatus({
                  memberIDs: selectedGroupData.group.memberIDs,
                  oppositeMemberID: selectedGroupData.oppositeMemberID,
                })}

                {getProfileLinkComp()}
                {getTypingStatus()}
              </SubHeading>
            </TextSection>
          </TopProfileLeft>

          <CustomButton
            style={{ width: "35px", padding: 0 }}
            onClick={showChatMoreMenu}
            icon={<BsThreeDotsVertical />}
          ></CustomButton>
        </TopProfile>
      </TopPart>

      <Messages id="chat-panel">
        {messages.map((item) => (
          <MessageBox
            group={group}
            message={item}
            oppositeMember={oppositeMember}
          />
        ))}
      </Messages>

      <BottomPart>{getBottomPart()}</BottomPart>
    </Container>
  );

  function getBottomPart() {
    if (loggedInUserIsBlocked) return "You have been blocked";
    if (oppositeUserIsBlocked) return "You have blocked the other person";
    return loading ? <LoadingSection /> : theBottomPart;
  }

  function getProfileLinkComp() {
    if (oppositeMember.isAnonymous)
      return <Username>{oppositeMember.username}</Username>;
    return (
      <SemanticButton
        semanticHref={getProfileLink(oppositeMember.username)}
        onClick={goToProfile(oppositeMember.username)}
      >
        <Username> @{oppositeMember.username}</Username>
      </SemanticButton>
    );
  }

  function showChatMoreMenu() {
    let options = [];

    options = [
      {
        name: "Manage Blocking",
        onClick: manageBlocking,
      },
    ];

    setForm({ options: options });
  }

  function manageBlocking() {
    let oppositeMemberID = selectedGroupData.oppositeMemberID;

    setForm({
      title: "Manage Blocking",
      component: (
        <BlockUnblockInterface
          receiverUserData={oppositeMember}
          receiverUserID={oppositeMemberID}
          senderUserID={selectedGroupData.myID}
          callback={() => {
            loadNewMessages(groupID);
          }}
          senderIdentityType={getUserIdentityType({
            chatGroup: group,
            userID: selectedGroupData.myID,
          })}
          receiverIdentityType={getUserIdentityType({
            chatGroup: group,
            userID: oppositeMemberID,
          })}
        />
      ),
    });
  }

  function getTypingStatus() {
    if (!oppositeMemberLastTypedAt) return null;

    let theDate = new Date(oppositeMemberLastTypedAt).getTime();
    let now = new Date().getTime();

    if (now - theDate < 3000) return <Typing>Typing...</Typing>;
  }

  function updateMessage(e) {
    setMessageText(e.target.value);
    sendTypingMessage();
    onChangeStop(selectedGroupData.group.groupID, () => {
      sendTypingMessage(true);
    });
  }

  function sendTypingMessage(hasStopped = false) {
    if (window.chatSocket) {
      window.chatSocket.emit("typing", {
        hasStopped: hasStopped,
        groupData: selectedGroupData.group,
        receiverUserID: selectedGroupData.oppositeMemberID,
      });
    }
  }

  async function uploadImage() {
    let pseudoID = nanoid();

    let images = await selectFile({ onlyImage: true });

    if (!images) return null;

    if (!window.localImages) {
      window.localImages = {};
    }

    let theImage = images[0];

    setLoading(true);

    window.localImages[pseudoID] = theImage;

    if (theImage) {
      try {
        let fileData = await compressAndUploadFile(null, theImage);
        let theImageData = { type: "S3_UPLOAD", data: fileData.fileName };

        addMessage({
          message: { image: theImageData },
          pseudoID: pseudoID,
          isNew: true,
          authorUserID: loggedInUserID,
        });

        setLoading(false);

        reachChatPanelEnd();

        serverLine
          .post("/message", {
            message: { image: theImageData },
            chatGroupObjectID: group._id,
          })
          .then(() => {
            markAsSent(pseudoID);
          });
      } catch (e) {
        setLoading(false);
        window.popupAlert(e.message);
      }
    }
  }

  function postMessage() {
    if (!messageText) return window.doAlert("Message can't be empty");
    if (!messageText.trim()) return window.doAlert("Message can't be empty");

    if (handleSendingIfBlocked({ data: selectedGroupData, popupAlert })) {
      return;
    }

    let pseudoID = nanoid();

    serverLine
      .post("/message", {
        message: { text: messageText },
        chatGroupObjectID: group._id,
      })
      .then(() => {
        markAsSent(pseudoID);
      });

    addMessage({
      message: { text: messageText },
      pseudoID: pseudoID,
      isNew: true,
      authorUserID: loggedInUserID,
    });

    reachChatPanelEnd();
    setMessageText("");
  }
}

/*
    TODO
    * Show My Messages along with other messages
    * Edit the message area
*/
