import { styled } from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";

import selectFile from "../../controllers/selectFile.js";
import compressAndUploadFile from "../../controllers/compressAndUploadFile.js";
import { serverLine } from "../../controllers/serverLine.js";

import Context from "../../Context.js";

import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
import CustomToggle from "../helperComponents/CustomToggle.js";
import ProfilePicture from "../helperComponents/ProfilePicture.js";
import ShowSelectedImage from "../helperComponents/ShowSelectedImage.js";
import DropDownInput from "../helperComponents/DropDownInput.js";
import MaterialInput from "../helperComponents/MaterialInput.js";
import CustomButton from "../helperComponents/CustomButton.js";

import LoadingSection from "../helperComponents/LoadingSection.js";
import DropDownLocationInput from "../helperComponents/DropDownLocationInput.js";
import GalleryEditorAndPresenter from "./GalleryEditorAndPresenter.js";
import BeautifulTitle from "../helperComponents/BeautifulTitle.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 10px;
  align-items: center;
  width: 45vw;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Inputs = styled.div`
  background: var(--glassGradient);
  border: 1px solid var(--foreground);
  border-radius: 10px;
  box-shadow: var(--lightShadow);
  padding: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 20px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    margin-top: 20px;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;
`;

const Textarea = styled.textarea`
  font-size: 18px;
  border: none;
  border-bottom: 1px solid var(--translucentHard);
  background: transparent;
  border-radius: 0;
  color: var(--color);
  /* text-align: center; */
  width: 100%;
  font-family: "Inter";
  resize: none;

  padding: 15px 0;

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
`;

export default function AskPreferenceDetails({ title }) {
  const { loggedInUser, setLoggedInUser, popupAlert } = useContext(Context);
  const [config, setConfig] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser && !config) {
      let newState = {};
      newState = { ...loggedInUser };
      setConfig(newState);
    }
  }, [loggedInUser]);

  if (loading || !loggedInUser || !config) return <LoadingSection />;

  return (
    <Container>
      <br />
      <BeautifulTitle>
        {title ? title : "Complete your dating profile"}
      </BeautifulTitle>
      <br />
      <GalleryEditorAndPresenter
        allowEditing={true}
        images={loggedInUser.images}
      />
      <Inputs>
        <MaterialInput
          multiline={true}
          value={config.favoriteSeries}
          onChange={makeChange("favoriteSeries")}
          label={"Favorite Series"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          multiline={true}
          value={config.favoriteMovies}
          onChange={makeChange("favoriteMovies")}
          label={"Favorite Movies"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          multiline={true}
          value={config.favoriteSingers}
          onChange={makeChange("favoriteSingers")}
          label={"Favorite Singers"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          multiline={true}
          value={config.favoriteBooks}
          onChange={makeChange("favoriteBooks")}
          label={"Favorite Books"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          value={config.favoriteFoods}
          multiline={true}
          onChange={makeChange("favoriteFoods")}
          label={"Favorite Foods"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          value={config.favoriteSports}
          multiline={true}
          onChange={makeChange("favoriteSports")}
          label={"Favorite Sports"}
          placeholder={"You can mention more than one"}
        />

        <MaterialInput
          value={config.celebrityCrush}
          multiline={true}
          onChange={makeChange("celebrityCrush")}
          label={"Celebrity Crush"}
          placeholder={"You can mention more than one"}
        />
      </Inputs>
      <br /> <br />
      <CustomButton
        style={{ width: "50%", padding: "20px 0" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="filled"
      >
        Save
      </CustomButton>
      <br /> <br />
    </Container>
  );

  function makeChange(fieldName, isString) {
    return (e) => {
      if (isString) {
        let newItem = { ...config };
        newItem[fieldName] = e;
        setConfig(newItem);
        return true;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);
    };
  }

  async function save() {
    if (!loggedInUser.images) {
      return popupAlert("Please add images");
    }

    if (loggedInUser.images.length < 3) {
      return popupAlert("Please 3 images");
    }

    let toSend = {
      changes: {},
    };

    toSend.changes.onboarding = "datingInfoAdded";
    toSend.changes.favoriteSeries = config.favoriteSeries;
    toSend.changes.favoriteMovies = config.favoriteMovies;
    toSend.changes.favoriteSingers = config.favoriteSingers;
    toSend.changes.favoriteBooks = config.favoriteBooks;
    toSend.changes.favoriteFoods = config.favoriteFoods;
    toSend.changes.favoriteSports = config.favoriteSports;
    toSend.changes.celebrityCrush = config.celebrityCrush;

    let newConfig = { ...config };

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser, ...newConfig };

      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.datingInfoAdded = true;
      } else {
        newLoggedInUser.onboarding = { datingInfoAdded: true };
      }

      console.log("newLoggedInUser", newLoggedInUser);
      setLoggedInUser(newLoggedInUser);

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}
