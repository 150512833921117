import { PiMaskHappyFill } from "react-icons/pi";

export default function getChatTitle({ chatGroup, oppositeMember }) {
  let title = chatGroup.contentTitle;

  if (!title) {
    if (oppositeMember.isAnonymous) {
      title = oppositeMember.username;
    } else {
      title = oppositeMember.name;
    }
  }

  return title;
}
